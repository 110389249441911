import React, { useState } from 'react';
import chatonRoux from "../../assets/pictures/chaton.png";

const FormKittens = () => {
  const [nomKittens, setNomKittens] = useState('');
  const [ageKittens, setAgeKittens] = useState('');
  const [genderKittens, setGenderKittens] = useState('');
  const [dateAdoptionKittens, setDateAdoptionKittens] = useState("");
  const [descriptionKittens, setDescriptionKittens] = useState('');
  const [isCheckedSterileKittens, setIsCheckedSterileKittens] = useState(false);
  const [isCheckedIdentifierKittens, setIsCheckedIdentifierKittens] = useState(false);
  const [isCheckedVacKittens,setIsCheckedVacKittens] = useState(false);
  const [isCheckedPrimoVacKittens,setIsCheckedPrimoVacKittens] = useState(false);
  const [sickness, setSickness] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [mainPicture,setMainPicture]=useState('');
  const [morePictureKittens,setMorePictureKittens]=useState([]);

  let userLocalStor = localStorage.getItem("token");

  const handleVacKittens = (e) => {
    setIsCheckedVacKittens(e.target.checked);
    updateSicknessArray("Vaccin", e.target.checked);
  };

  const handlePrimoVacKittens = (e) => {
    setIsCheckedPrimoVacKittens(e.target.checked);
    updateSicknessArray("Primo-vaccin", e.target.checked);
  };

  const handleSterileKittens = (e) => {
    setIsCheckedSterileKittens(e.target.checked);
    updateSicknessArray("Sterile", e.target.checked);
  };

  const handleIdentifierKittens = (e) => {
    setIsCheckedIdentifierKittens(e.target.checked);
    updateSicknessArray("Identifier", e.target.checked);
  };
  const updateSicknessArray = (value, checked) => {
    if (checked) {
      setSickness((prevSickness) => [...prevSickness, value]);
    } else {
      setSickness((prevSickness) => prevSickness.filter((item) => item !== value));
    }
  };

  const handleGenreChange = (e) => {
        setGenderKittens(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedImages = selectedFiles.slice(0, 5); // Limitez le nombre d'images à 5
    setMorePictureKittens(selectedImages);
  };

  const formData = new FormData();
  formData.append('name', nomKittens); //required:true
  formData.append("birthDate",ageKittens);//required:true
  formData.append('gender', genderKittens);//required:true
  if (morePictureKittens.length > 0) {
    formData.append('pictures', morePictureKittens[0]); // Utilisez le premier fichier
    for (let i = 1; i < morePictureKittens.length; i++) {
      formData.append('pictures', morePictureKittens[i]);
    }
  }   //required:false   
  formData.append('mainPicture',mainPicture);//required:true
  formData.append("adoption", dateAdoptionKittens);//required:false
  formData.append("description",descriptionKittens);//required:false
  formData.append("sickness", JSON.stringify(sickness));//required:false
  
  const addKitten = (e) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;
  
    fetch(`${apiUrl}/api/kittens/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userLocalStor}`
      },
      body: formData
    })
    .then(response => {
      let erreur;
      if ((!nomKittens || !ageKittens || !genderKittens|| !mainPicture|| !descriptionKittens)) {
        erreur = 'Veuillez saisir tous les champs avec une astérisque';
        setErrorMessage(erreur);
        return;
      }
      if (response.ok){
        erreur="chaton bien enregistré"
      setNomKittens('');
      setAgeKittens('');
      setGenderKittens('');
      setDateAdoptionKittens('');
      setDescriptionKittens('');
      setIsCheckedSterileKittens(false);
      setIsCheckedIdentifierKittens(false);
      setIsCheckedVacKittens(false);
      setSickness([]);
      setMainPicture('');
      setMorePictureKittens([]);
      }
      if (erreur) {
        setErrorMessage(erreur);
      } else {
        setErrorMessage('');
      }
    })
    .then(data => {
      if(!data)
      {
        console.log("pas de données pour le chatons T.T")
      }
      if(data){
              console.log("Chat ajouté avec succès (front)")
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  return (
    <div className='formGenerale'>
      <h5>Ajouter un chaton</h5>
      <form className='formFlex' id='kittensForm' method="POST" onSubmit={addKitten} encType="multipart/form-data">
        <label htmlFor="nomKittens" name="nomKittens">*Nom :</label>
        <input type="text" id="nomKittens" name="name" value={nomKittens} onChange={(e) => setNomKittens(e.target.value)} placeholder="Son nom" />
        <label htmlFor="ageKittens">*Sélectionnez son age :</label>
        <input type="date" id="ageKittens" name="birthDate" value={ageKittens} onChange={(e) => setAgeKittens(e.target.value)} />

        <label>*Genre :</label>
        <div className='containerGenre'>
          <div className='inputFemelle'>
            <input type="radio" id="femelleKittens" name="gender" value="F" checked={genderKittens === 'femelle'} onChange={handleGenreChange}/>
            <label htmlFor="femelleKittens">Femelle</label>
          </div>
          <div className='inputMale'>
            <input type="radio" id="maleKittens" name="gender" value="M" checked={genderKittens === 'male'} onChange={handleGenreChange} />
            <label htmlFor="maleKittens">Mâle</label>
          </div>
          <div className='inputgenderInconnu'>
            <input type="radio" id="genderInconnuKittens" name="gender" value="I" checked={genderKittens === 'inconnu'} onChange={handleGenreChange}/>
            <label htmlFor="genderInconnuKittens">Inconnu</label>
          </div>
        </div>

        <label>Situation :</label>
<div className="containerSituation">
  <div>
    <input type="checkbox" id="situation1Kittens" name="situationKittens" value="Primo-vaccin" checked={isCheckedPrimoVacKittens} onChange={handlePrimoVacKittens}/>
    <label htmlFor="situation1Kittens">Primo-vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation2Kittens" name="situationKittens" value="Vaccin" checked={isCheckedVacKittens} onChange={handleVacKittens}/>
    <label htmlFor="situation2Kittens">Vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation3Kittens" name="situationKittens" value="Sterile" checked={isCheckedSterileKittens} onChange={handleSterileKittens}/>
    <label htmlFor="situation3Kittens">Stérile</label>
  </div>
  <div>
    <input type="checkbox" id="situation4Kittens" name="situationKittens" value="Identifier" checked={isCheckedIdentifierKittens} onChange={handleIdentifierKittens}/>
    <label htmlFor="situation4Kittens">Identifier</label>
  </div>
</div>
          <>
            <label htmlFor="date">Disponible le :</label>
            <input type="date" id="date" name="adoption" value={dateAdoptionKittens} onChange={(e) => setDateAdoptionKittens(e.target.value)} />
          </>
          <label htmlFor="mainPictureKittens">*Image principale :</label>
              <input type="file" name="mainPicture" id="mainPictureKittens"  onChange={(e) => setMainPicture(e.target.files[0])}/>

              <label htmlFor="PictureEvent">Image en plus :</label>
              <div className='containerPicture'>
              <input className='inputFile' type="file" name="pictures" id="PictureActu"  multiple onChange={handleImageChange} />
              </div>

        <label htmlFor="descriptionKittens">Description :</label>
        <textarea id="descriptionKittens" name='description' value={descriptionKittens} onChange={(e) => setDescriptionKittens(e.target.value)} rows="10" placeholder="Description du chaton"></textarea>
        <div className='btnEnvoiAdmin'>
          <img src={chatonRoux} alt="chat noir et blanc" />
          <button type="submit" className='buttonSubmit'>Envoyez</button>
          <p>{errorMessage}</p>
        </div>
      </form>
    </div>
  );
};

export default FormKittens;