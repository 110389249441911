import React, { useState, useEffect } from 'react';
import "../stylesMin/Activities.css";
import iconeButtonCatBlackEvenement from "../assets/icones/buttonCatBlack.png";
import iconeButtonCatBlackActu from "../assets/icones/buttonCatBlackActu.png";
import iconeButtonCatColorEvenement from "../assets/icones/buttonCatColor.png";
import iconeButtonCatColorActu from "../assets/icones/buttonCatColorActu.png";
import CardsActu from '../components/CardsActu';
import chatchute from "../assets/pictures/chatchute3.png";
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function Acivities() {
  const [statutEvent, setStatutEvent] = useState("event");
  const [dataActivity, setDataActivity] = useState([]);
  const [evenementButtonSrc, setEvenementButtonSrc] = useState(iconeButtonCatColorEvenement);
  const [actuButtonSrc, setActuButtonSrc] = useState(iconeButtonCatBlackActu);
  const role = useSelector((state) => state.role);


  const handleEvenementButtonClick = () => {
    setStatutEvent("event");
    setEvenementButtonSrc(iconeButtonCatColorEvenement);
    setActuButtonSrc(iconeButtonCatBlackActu);
   
  };

  const handleActuButtonClick = () => {
    setStatutEvent("actu");
    setEvenementButtonSrc(iconeButtonCatBlackEvenement);
    setActuButtonSrc(iconeButtonCatColorActu);
  };

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    let fetchData = () => {
    let url;

    if (statutEvent === "event") {
      url = `${apiUrl}/api/events/`;
      setStatutEvent(true);
    } else if (statutEvent === "actu") {
      url = `${apiUrl}/api/actualities/`;
      setStatutEvent(false);
    }

    fetch(url)
    .then(response => {
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.json();
    })
    .then(data => {
      if (statutEvent === "event") {
        const filterEvents = data.filter((el)=> el.type === "event");
        setDataActivity(filterEvents);
      } else if (statutEvent === "actu") {
        const filterActus = data.filter( (el) => el.type === "actuality");
        setDataActivity(filterActus);
      }
    })
    .catch(error => {
        console.error("Fetch error:", error);
    });

  };
  fetchData();
  }, [statutEvent]);


  return (
    <div>
 { role!=="admin" ?  <section className='activities'>
      <div className='catButton'>
        <button className='catButtonEvenement' onClick={handleEvenementButtonClick}>
          <img src={evenementButtonSrc} alt="icone chat coucher" />
        </button>
        <button className='catButtonActu' onClick={handleActuButtonClick}>
          <img src={actuButtonSrc} alt="icone chat coucher"/>
        </button>
      </div>
      <CardsActu event={statutEvent} dataActivity={dataActivity} />
      <img className='chatChute' src={chatchute} alt="chat qui chute" />

    </section> : <Navigate to="/activitiesEdit"/>}
    </div>
  );
}

export default Acivities;
