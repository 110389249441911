import React from 'react';
import "../stylesMin/ModaleData.css"
import chatSilhouette from "../assets/icones/chat-Silhouette-Gris.png"
import FormCatEdit from './formulaire/FormCatEdit';
import FormKittenEdit from './formulaire/FormKittensEdit';
import FormEventEdit from './formulaire/FormEventEdit';
import FormActuEdit from './formulaire/FormActuEdit';

const ModaleData = ({ onClose, formOf, data, dataActivity}) => {

  const handleModalClose = () => {
    onClose();
  };

  return (
    <div className="modale-container-form">
      <img className='chatSilhouetteGris' src={chatSilhouette} alt="silhouette de chat allongé" />
      <div className="modale-form">
        <div className='en-teteModale-form'>
          <span></span>
          <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
        </div>
        <div className='formulaireModale'>
          {(formOf === "cats" &&  <FormCatEdit dataObject={data}/>)}
          {(formOf === "kittens" && <FormKittenEdit dataObject={data} />)}
          {(formOf === true &&  <FormEventEdit dataActivity={dataActivity} />)}
          {(formOf === false &&  <FormActuEdit dataActivity={dataActivity} />)}
        </div>
      </div>
    </div>
  );
};

export default ModaleData;