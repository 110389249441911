import React from 'react';
import "../stylesMin/ModaleData.css";
import chatSilhouette from "../assets/icones/chat-Silhouette-Gris.png";
import { useState } from 'react';

function DeleteModal({ dataActivity, data, onClose, animal }) {
const [success, setSuccess] = useState(false);
const apiUrl = process.env.REACT_APP_API_URL;
  let userLocalStor = localStorage.getItem("token");
 
    const handleDelete = (e) => {
      e.preventDefault();
      let url;
          if(dataActivity){
            const id = dataActivity._id;
            if(dataActivity.type==="event"){
            url = `${apiUrl}/api/events/${id}` }
            if(dataActivity.type==="actuality") {
             url = `${apiUrl}/api/actualities/${id}`
            }
            
          }
          if(data) {
            const id = data._id;     
            url = `${apiUrl}/api/${animal}/${id}`
          }

      fetch( url, {
        method:"DELETE",
        headers: {
          'Authorization':  `Bearer ${userLocalStor}`,
        }
      }).then(response => 
        {
        if(!response.ok) {
          throw new Error('La suppression a échoué');
        }
        setSuccess(true);
      }).catch(err => console.error('Erreur lors de la suppression :', err)
      )         
        
    }

 
 
  const handleModalClose = () => {
    onClose();
  };

  return (

<div className="modale-container-form">
<img className='chatSilhouetteGris' src={chatSilhouette} alt="silhouette de chat allongé" />
<div className="modale-form">
  <div className='en-teteModale-form'>
    <span></span>
    <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
  </div>
  <div className='formulaireModale'>
  {success? <p className='modalMsg'>Suppression réussie.</p> : <div className='deleteContainer'>
    <p>Voulez-vous supprimer cette carte?</p>
    <div className='deleteButtons'>
     <button id='deleteBtn' onClick={handleDelete} >Supprimer</button>
   <button id='cancelDeleteBtn' onClick={handleModalClose}>Annuler</button>
     </div>
    </div>}
  </div>
</div>
</div>
  )
}

export default DeleteModal