import React, { useState, useEffect } from 'react';
import facebook from "../assets/pictures/facebook.png";
import chatFond1 from "../assets/pictures/chatfond1.png";
import chatFond2 from "../assets/pictures/chatfond2.png";
import chatFond3 from "../assets/pictures/chatfond3.png";
import chatFond4 from "../assets/pictures/chatfond4.png";
import chatFond5 from "../assets/pictures/chatfond5.png";
import chatFond6 from "../assets/pictures/chatfond6.png";
import vide from "../assets/pictures/vide.png";
import Modale from './Modale';
import BtnEdition from "../components/BtnEdition.jsx"

// Créez un tableau d'images de chat de fond
const chatFonds = [
  chatFond1,
  vide,
  chatFond2,
  vide,
  chatFond3,
  vide,
  chatFond4,
  vide,
  chatFond5,
  vide,
  chatFond6,
];

const CardsActu = ({ adminToken, event, dataActivity }) => {
  const [selectedObjet, setSelectedObjet] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModaleClick = (objet) => {
    setSelectedObjet(objet);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains('modale-container')) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const sortedActivities = [...dataActivity].sort((a, b) => new Date(b.date_begin) - new Date(a.date_begin));

  return (
    <div className='cardsEvent'>
      {sortedActivities.map((objet, index) => {

function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

// Formatage des dates
const dateUpgrade = formatDate(new Date(objet.dateUpgrade));
const date_begin = formatDate(new Date(objet.date_begin));
const date_end = formatDate(new Date(objet.date_end));



        return (
          <div className={`${index % 4 < 2 ? 'chat-right' : 'chat-left'}`} key={objet._id} alt={`description de l'article ${objet.title}`}>
            <div className='espace'>
              <img className='chat-background' src={chatFonds[index % chatFonds.length]} alt="chat"/></div>

            <div className='infoEvent'>
            {adminToken && <BtnEdition dataActivity={objet} formOf={event} typeData={objet.type} dataId={objet._id} onClick={() => handleModaleClick(objet)} style2={{width:"100%", textAlign:"right"}} />} 
              <div className='en-tete'>
                <div className='date'>
                  <span>Lieu<br />{objet.place}</span>
                  <span>Mise à jour le<br /> {dateUpgrade} </span>
                </div>
                <h3 className='eventTitle'>{objet.title}</h3>
              </div>
              {event === true ?
                <div className={`infoUseful ${window.innerWidth > 1200 && index % 2 === 0 ? 'flex-reverse' : ''}`}>
                  <img className='mainPictureActu' src={objet.mainPicture} alt="Visuel de l'évènement" onClick={() => handleModaleClick(objet)} />
                  <div className='infoEcart'>
                    <p className='descriptionEvent'>{objet.description}</p>
                    <div className='infoGeneralEvent'>
                      <p>Date de l'évènement<br /><br /> Du {date_begin}<br />au {date_end}</p>
                      <div className='lienEvent'>
                        <p>Lien de l'évènement</p>
                        <button className='btnEvent'><a href={objet.link} >Cliquez-ici</a></button>
                      </div>
                      <div className='lienFB'>
                        <p className='textEvent'>Partager</p>
                        <a href="https://www.facebook.com/"><img src={facebook} alt="Logo facebook" className='logoFacebook' /></a>
                      </div>
                    </div>
                  </div>
                </div>
              :
                <div className={`infoUseful ${window.innerWidth > 1200 && index % 2 === 0 ? 'flex-reverse' : ''}`}>
                  <img className='mainPictureActu' src={objet.mainPicture} alt="Visuel de l'évènement" onClick={() => handleModaleClick(objet)} />
                  <div className='infoEcart'>
                    <p className='descriptionEvent'>{objet.description}</p>
                    <div className='infoGeneralActu'>
                      <p>Date de l'évènement<br /><br /> Le {date_begin}</p>
                      <div className='lienEvent'>
                        <p>Soutenir l'association</p>
                        <button className='btnAsso'><a href="https://www.helloasso.com/associations/association-www.casperetlulu" >Cliquez-ici</a></button>
                      </div>
                      <div className='lienEventActu'>
                        <p>Lien de l'évènement</p>
                        <button className='btnEvent'>
                            {objet.link ? (
                              <a href={objet.link}>Cliquez-ici</a>
                            ) : (
                              <a href="https://www.helloasso.com/associations/association-www.casperetlulu">Pas de lien dispo</a>
                            )}
                          </button>
                      </div>
                      <div className='lienFBActu'>
                        <p className='textEvent'>Partager</p>
                        <a href="test"><img src={facebook} alt="Logo facebook" className='logoFacebook' /></a>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className='espace'><span></span></div>
          </div>
        )
      })}
      {isModalOpen && selectedObjet && (
        <Modale objet={selectedObjet} typeData="pageActu" onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default CardsActu;
