import React,{useState,useEffect} from 'react';
import "../stylesMin/Contact.css";
import adress from "../assets/icones/adresse_1.png";
import contactBulle from "../assets/pictures/bulle_contact.png";
import contactBulle2 from "../assets/pictures/bulledialogue2.1.png";


function Contact() {
  const [imageSrc, setImageSrc] = useState(contactBulle);

  useEffect(() => {
    // Fonction pour mettre à jour l'état de l'image en fonction de la largeur
    const mettreAJourImage = () => {
      if (window.innerWidth < 720) {
        setImageSrc(contactBulle2);
      } else {
        setImageSrc(contactBulle);
      }
    };
    // Écoutez les changements de taille de fenêtre
    window.addEventListener('resize', mettreAJourImage);
    // Appelez la fonction pour définir l'état initial
    mettreAJourImage();
    // Nettoyez l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', mettreAJourImage);
    };
  }, []);
    
  return (
    <div className='contactContainer' id='contact'>
        <img className="contactBulle" src={imageSrc} alt="dessin d'une emprunte de chat géante avec un chat tigré de profil" />
      <div className='contactContent'>
        <p className='contactText'>Des questions ?  Voici les différents points de contact !</p>
        <div className='contactText2 coordonnees'>
          <div className='contactText2 coordonnee tel'><i className="fa-solid fa-phone-volume"></i><p>Téléphone: 06.81.96.05.98</p></div>
          <div className='contactText2 coordonnee email'><i className="fa-regular fa-envelope"></i><p>E-mail: mf.rabin@sfr.fr</p></div>
        </div>
<div className='contactText2 adress'>
  <div className='houseDiv'>
    <img className='houseImg' src={adress} alt='icone maison' />
    </div>
    <p className='adressText'>Adresse: 14 rue de la forge, 28310 Janville-en-beauce<br/> (lieu dit Outrouville)</p>
    </div>
      </div>
    </div>
  )
}

export default Contact
