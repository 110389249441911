import { legacy_createStore as createStore} from 'redux'
import {produce} from "immer";

const initialState = {
    userToken:"",
    adminToken: "",
    role:"",
    username: "",
};

export const store = createStore(reducer, initialState);

export function reducer(state=initialState, action) {
    if(action.type === "getAdminToken"){
        return produce(state, (draft) => {
            draft.adminToken = action.payload.adminToken
        })
    }

    if(action.type ==="getUserToken") {
        return produce(state, (draft) => {
            draft.userToken = action.payload.userToken
        })
    }

    if(action.type=== "getRole") {
        return produce(state, (draft) => {
            draft.role = action.payload.role
        })
    }
    if(action.type==="getUsername") {
        return produce(state, (draft)=> {
            draft.username = action.payload.username
        })
    }

    return state


};
