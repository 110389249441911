import React, { useState} from 'react';
import chatonRoux from "../../assets/pictures/chaton.png";
import Sterile from "../../assets/icones/sterile.png";
import Primo from "../../assets/icones/vaccin.png";
import Vaccin from "../../assets/icones/vaccinrappel.png";
import Identifier from "../../assets/icones/carteidentite.png";

const FormKittens = ({ dataObject }) => {
    let data = dataObject;
    const birthDate = new Date(data.birthDate);
    const birthValue = birthDate.toISOString().split('T')[0];
    const adoption = data.adoption ? new Date(data.adoption) : null;
    const adoptionValue = adoption ? adoption.toISOString().split('T')[0] : null;
    const [nomKittens, setNomKittens] = useState(data.name);
    const [ageKittens, setAgeKittens] = useState(birthValue);
    const [genderKittens, setGenderKittens] = useState(data.gender);
    const [dateAdoptionKittens, setDateAdoptionKittens] = useState(adoptionValue);
    const [descriptionKittens, setDescriptionKittens] = useState(data.description);
    const [isCheckedSterileKittens, setIsCheckedSterileKittens] = useState(false);
    const [isCheckedIdentifierKittens, setIsCheckedIdentifierKittens] = useState(false);
    const [isCheckedVacKittens,setIsCheckedVacKittens] = useState(false);
    const [isCheckedPrimoVacKittens,setIsCheckedPrimoVacKittens] = useState(false);
    const sicknessData = useState(data.sickness[0]);
    const [sicknessPut,setSicknessPut]=useState(false)
    const [sickness,setSickness]=useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [mainPicture, setMainPicture] = useState(data.mainPicture);
    const [morePictureKittens, setMorePictureKittens] = useState(data.pictures || []);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isAvailable, setIsAvailable] = useState(dataObject.adoption ? false : true);
    const [isModified, setIsModified] = useState(false);  
    let userLocalStor = localStorage.getItem("token");

    const handlePrimoVacChange = (e) => {
        setSicknessPut(true)
        setIsCheckedPrimoVacKittens(e.target.checked);
        updateSicknessArray("Primo-vaccin", e.target.checked);
    };

      const handleVacChange = (e) => {
        setSicknessPut(true)
        setIsCheckedVacKittens(e.target.checked);
        updateSicknessArray("Vaccin", e.target.checked);
      };
      
      const handleSterileChange = (e) => {
        setSicknessPut(true)
        setIsCheckedSterileKittens(e.target.checked);
        updateSicknessArray("Sterile", e.target.checked);
      };
      
      const handleIdentifierChange = (e) => {
        setSicknessPut(true)
        setIsCheckedIdentifierKittens(e.target.checked);
        updateSicknessArray("Identifier", e.target.checked);
      };

      const updateSicknessArray = (value, checked) => {
        if (checked) {
          setSickness((prevSickness) => [...prevSickness, value]);
          return sickness
        } else {
          setSickness((prevSickness) => prevSickness.filter((item) => item !== value));
          return sickness
        }
      };
      
      const handleAddImage = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const selectedImages = selectedFiles.slice(0, 5 - morePictureKittens.length); // Limitez le nombre d'images ajoutées pour ne pas dépasser 5
        const newImages = selectedImages.map((file) => URL.createObjectURL(file)); // Créez des URL d'objets pour les nouvelles images
        setMorePictureKittens((prevImages) => [...prevImages, ...newImages]);
    };
    
    const handleRemoveImage = (index) => {
        const updatedImages = morePictureKittens.filter((_, i) => i !== index);
        setMorePictureKittens(updatedImages);
    };

    const handleGenreChange = (e) => {
        setGenderKittens(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAvailabilityChange = (e) => {
        setIsAvailable(e.target.checked);
    };

    const formData = new FormData();
    formData.append('name', nomKittens); //required:true
    formData.append("birthDate", ageKittens);//required:true
    formData.append('gender', genderKittens);//required:true
    if (morePictureKittens.length > 0) {
        formData.append('pictures', morePictureKittens[0]); // Utilisez le premier fichier
        for (let i = 1; i < morePictureKittens.length; i++) {
            formData.append('pictures', morePictureKittens[i]);
        }};
    formData.append('mainPicture', mainPicture);//required:true
    formData.append("description", descriptionKittens);//required:false//required:false
    if (isAvailable === false) {
        formData.append('adoption', dateAdoptionKittens);
    }

    const updateKitten = (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        let id = data._id;
        formData.append('id', id); // Ajouter l'ID au formData
        if (sickness === sicknessData){
            formData.append("sickness", JSON.stringify(sicknessData));
        } if (sickness !== undefined && sickness!==sicknessData && sicknessPut) {
            formData.append("sickness", JSON.stringify(sickness));
        }
        fetch(`${apiUrl}/api/kittens/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${userLocalStor}`
            },
            body: formData
        })
            .then(response => {
                let erreur;
                if ((!nomKittens || !ageKittens || !genderKittens || !mainPicture || !descriptionKittens)) {
                    erreur = 'Veuillez saisir tous les champs avec une astérisque';
                    setErrorMessage(erreur);
                    return;
                }
                if (response.ok) {
                    erreur = "chaton bien enregistré"
                    setIsModified(true);
                }
                if (erreur) {
                    setErrorMessage(erreur);
                } else {
                    setErrorMessage('');
                }
            })
            .then(data => {
                if (data) {
                    console.log("Chat ajouté avec succès (front)")
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <div>
            {isModified ? <p>{errorMessage}</p> : <div className='formGenerale'>
                <h5>Modifier un chaton</h5>
                <form className='formFlex' id='kittensForm' method="PUT" onSubmit={updateKitten} encType="multipart/form-data">
                    <label htmlFor="nomKittens" name="nomKittens">*Nom :</label>
                    <input type="text" id="nomKittens" name="name" value={nomKittens} onChange={(e) => setNomKittens(e.target.value)} placeholder="Son nom" />
                    <label htmlFor="ageKittens">*Sélectionnez son age :</label>
                    <input type="date" id="ageKittens" name="birthDate" value={ageKittens} onChange={(e) => setAgeKittens(e.target.value)} />

                    <label>*Genre :</label>
                    <div className='containerGenre'>
                        <div className='inputFemelle'>
                            <input type="radio" id="femelleKittens" name="gender" value="F" checked={genderKittens === 'F'} onChange={handleGenreChange} />
                            <label htmlFor="femelleKittens">Femelle</label>
                        </div>
                        <div className='inputMale'>
                            <input type="radio" id="maleKittens" name="gender" value="M" checked={genderKittens === 'M'} onChange={handleGenreChange} />
                            <label htmlFor="maleKittens">Mâle</label>
                        </div>
                        <div className='inputgenderInconnu'>
                            <input type="radio" id="genderInconnuKittens" name="gender" value="I" checked={genderKittens === 'I'} onChange={handleGenreChange}/>
                            <label htmlFor="genderInconnuKittens">Inconnu</label>
                        </div>
                    </div>

                    <label>Situation :</label>   
                    <p>données actuelles</p>  
                    <div className='donneesDataSickness'>   
                    {JSON.parse(data.sickness).includes("Primo-vaccin") && (<div> <p>Primo-vaccin</p> <img className='situationLogo' src={Primo} alt='primo-vaccin fait' /></div> )}
                    {JSON.parse(data.sickness).includes("Vaccin") && (<div> <p>Rappel de vaccin </p> <img className='situationLogo' src={Vaccin} alt='vaccin rappel fait' /></div> )}
                    {JSON.parse(data.sickness).includes("Sterile") && (<div> <p>Stérilisé</p> <img className='situationLogo' src={Sterile} alt='stérilisation faite' /></div> )}
                    {JSON.parse(data.sickness).includes("Identifier") && (<div> <p>Identifié</p> <img className='situationLogo' src={Identifier} alt='identification faite' /></div> )}
                    </div>  
<p>données si modification</p>
<div className="containerSituation">
  
  <div>
    <input type="checkbox" id="situation1Kittens" name="situationKittens" value="Primo-vaccin" checked={isCheckedPrimoVacKittens} onChange={handlePrimoVacChange}/>
    <label htmlFor="situation1Kittens">Primo-vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation2Kittens" name="situationKittens" value="Vaccin" checked={isCheckedVacKittens} onChange={handleVacChange}/>
    <label htmlFor="situation2Kittens">Vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation3Kittens" name="situationKittens" value="Sterile" checked={isCheckedSterileKittens} onChange={handleSterileChange}/>
    <label htmlFor="situation3Kittens">Stérile</label>
  </div>
  <div>
    <input type="checkbox" id="situation4Kittens" name="situationKittens" value="Identifier" checked={isCheckedIdentifierKittens}onChange={handleIdentifierChange}/>
    <label htmlFor="situation4Kittens">Identifier</label>
  </div>
</div>
    <>
    <div className='avaibility' style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "10px" }}>
    <label htmlFor="availabilityCheckbox">Chat disponible tout de suite</label>
    <input type="checkbox" id="availabilityCheckbox" name="availability" checked={isAvailable} onChange={handleAvailabilityChange} style={{ display: "block", alignSelf: "end", marginBottom: "8px" }}/>
    </div>
    {isAvailable === false && <> <label htmlFor="date">Disponible le :</label>
    <input type="date" id="date" name="adoption" value={dateAdoptionKittens} onChange={(e) => setDateAdoptionKittens(e.target.value)} /></>}
    </>
    <label htmlFor="mainPictureKittens">*Image principale :</label>
    {(mainPicture && !selectedImage) ? <img src={mainPicture} alt="Aperçu du chaton" style={{ width: "40%" }} /> :
        <img src={selectedImage} alt="Aperçu du chaton" style={{ width: "40%" }} />}   
    <input type="file" name="mainPicture" id="mainPictureKittens" onChange={(e) => { setMainPicture(e.target.files[0]); handleImageChange(e) }} />
    <label htmlFor="PictureKittens">Image en plus :</label>
    <div className='containerPicture'>
        {morePictureKittens.map((image, index) => (
        <div key={index} className="image-container">
        {image && <img src={image} alt={`${index}`} className="preview-image" style={{ width: "100%" }}/>}
    <button type="button" onClick={() => handleRemoveImage(index)}>Supprimer</button>
    </div>
    ))}
    {morePictureKittens.length < 5 && (
        <input className='inputFile' type="file" name="pictures" id="PictureKittens" multiple onChange={handleAddImage} />
    )}
</div>







                 

                    {/* <label htmlFor="PictureEvent">Image en plus :</label>
                <div className='containerPicture'>
                    <input className='inputFile' type="file" name="pictures" id="PictureActu" multiple onChange={handleImageChange} />
                </div> */}

                    <label htmlFor="descriptionKittens">Description :</label>
                    <textarea
                        id="descriptionKittens"
                        name='description'
                        value={descriptionKittens}
                        onChange={(e) => setDescriptionKittens(e.target.value)}
                        rows="10" // Vous pouvez définir le nombre de lignes souhaité ici
                        placeholder="Description du chaton"
                    ></textarea>

                    <div className='btnEnvoiAdmin'>
                        <img src={chatonRoux} alt="chat noir et blanc" />
                        <button type="submit" className='buttonSubmit'>Envoyez</button>
                        <p>{errorMessage}</p>
                    </div>
                </form>
            </div>}
        </div>

    );
};

export default FormKittens;