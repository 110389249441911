import React, { useState, useEffect } from 'react';
import "../stylesMin/MainHomeContent.css";
import { Link } from 'react-router-dom';

function MainHomeContent({ picture, text, title, word, link }) {
  const [isRowReverse, setIsRowReverse] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setIsRowReverse(window.innerWidth > 850 && word === "chats");
    }

    // Ajoute un écouteur d'événement pour surveiller les changements de taille de la fenêtre
    window.addEventListener("resize", handleWindowResize);

    // Appelle la fonction de gestion de la taille de la fenêtre initialement
    handleWindowResize();

    // Nettoie l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className='mainHomeContent' style={{ flexDirection: isRowReverse ? 'row-reverse' : '' }}>
      <div className='textMain'>
        <h2 className='homeTitle1'>{title}</h2>
        <p className='homeText1'>{text}</p>
      </div>
      <div className='pictureContent'>
        <div className='pictureDiv'><img className="picture" src={picture} alt="chaton roux" /></div>
        <button className='catsButtons seeKittens'><Link to={link}>Voir les {word}</Link></button>
      </div>
    </div>
  );
}

export default MainHomeContent;
