import React, { useState, useEffect } from 'react';
import Cards from '../components/Cards';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Modale from '../components/ModaleData';

function CatsEdit() {
  const role = useSelector((state)=>state.role);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleModaleClick = () => {
    setIsModalOpen(true);
};


const handleCloseModal = () => {
    setIsModalOpen(false);
};

useEffect(() => {
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modale-container-form')) {
            setIsModalOpen(false);
        }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
        document.removeEventListener('click', handleOutsideClick);
    };
}, []);

  return (
    <section>
 
 { role==="admin" ?
 <section> 
 <div className='descriptionDiv'><p className='description'>L'adoption responsable est d'une importance capitale ! Gardez à l'esprit que votre nouvel ami aura besoin de soins et d'une alimentation adaptée à chaque étape de sa vie. Vous vous engagez pour plusieurs années de câlins et de joyeuses espiègleries :) Avant d'adopter, il est primordial de se poser les bonnes questions (le budget pour son bien-être, s'assurer que toute la famille du même foyer partage ce choix, etc.). Une adoption réfléchie est synonyme d'une adoption réussie ! Pour rappel, chaque chat sera stérilisé et identifié avant l’adoption.</p>
  </div>
    <div className='lienchatedition'>
    <div>
    <p className='soutien'>Soutenir l'association</p>
    <button className='btnAsso'><a href="https://www.helloasso.com/associations/association-casperetlulu" >Cliquez-ici</a></button>
    </div>
  </div>
  <Cards formOf="cats" role={role} animal="cats"/> 
  {isModalOpen && (
              <Modale formData="catPost" typeForm="POST" onClose={handleCloseModal} />
          )}
  </section>
  : <Navigate to="/connexion"/>}
</section>
  )
}

export default CatsEdit
