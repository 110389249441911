import React from 'react';
import "../stylesMin/modeEdition.css"

const BannerEdition = () => {
    return (
        <div className='editionMode'>
            <p>Mode édition</p>
            <button><a href="/admin">compte admin</a></button>
        </div>
    );
};

export default BannerEdition;