import React from 'react'
import "../stylesMin/Admin.css"
import FormCat from '../components/formulaire/FormCat';
import FormKittens from '../components/formulaire/FormKittens';
import FormEvent from '../components/formulaire/FormEvent';
import FormActu from '../components/formulaire/FormActu';
import {useSelector} from 'react-redux';



function Admin() {
const role =  useSelector((state) => state.role);
const pseudo = useSelector((state) => state.username);


  return (
    <>
      <h3 className='titleAdmin'>Bienvenue dans votre espace personnel<br/> {pseudo}</h3>
      <div className='formulaire'>
        <FormCat/>
        <FormKittens/>
      </div>
      <div className='formulaire'>
        <FormEvent/>
        <FormActu/>
      </div>
    </>
  )
}

export default Admin
