import React, { useLayoutEffect } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import patte from "../assets/icones/patte4.png";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearDatas } from '../actions';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 850);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Mettre à jour showMenu en fonction de la largeur de l'écran lorsqu'il change
    const handleResize = () => {
      setShowMenu(window.innerWidth > 850);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

const deconnect = () => {
  setIsClicked(false)
  console.log("deconnect");
  if (token) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    dispatch(clearDatas());
    window.location.href = "/";
    return localStorage;
  }
};


useEffect(() => {
  setTimeout(() => {
    if (location.hash.includes("#contact")) {
      console.log("setTimout hash");
      setIsClicked(true);
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 300);
}, []);

const scrollToContact = () => {
  setIsClicked(true);
  console.log("scroll", isClicked);
  document.getElementById('contact').scrollIntoView({ behavior: 'smooth', block: 'start' });

}
const backToContact = () => {
  window.location.href = "/#contact";
}

  return (
    <nav className="navHeader">
      <ul className="listHeader">
        <li className="puceLink"><Link onClick={() => setIsClicked(false)} className="Link" to="/">{location.pathname=== "/" && isClicked!==true && location.hash!=="#contact" && showMenu && <img src={patte} alt='empreinte de chat' />}Accueil</Link></li>
        <li className="puceLink">{location.pathname=== "/" ? <Link className="Link" onClick={scrollToContact}>{isClicked===true && showMenu && <img src={patte} alt='empreinte de chat' />}Contact</Link>
        : <Link onClick={backToContact} className="Link" >{isClicked===true && showMenu && <img src={patte} alt='empreinte de chat' />}Contact</Link>
        }</li>
        <li className="puceLink"><Link onClick={() => setIsClicked(false)} className="Link" to= "/cats">{(location.pathname=== "/cats" || location.pathname=== "/catsEdit" )  && showMenu && <img src={patte} alt='empreinte de chat' />}Chats</Link></li>
        <li className="puceLink"><Link onClick={() => setIsClicked(false)} className="Link" to="/kittens">{(location.pathname=== "/kittens"  || location.pathname=== "/kittensEdit") && showMenu && <img src={patte} alt='empreinte de chat' />}Chatons</Link></li>
        <li className="puceLink"><Link onClick={() => setIsClicked(false)} className="Link" to="/activities">{(location.pathname=== "/activities"  || location.pathname=== "/activitiesEdit") && showMenu && <img src={patte} alt='empreinte de chat' />}Activité</Link></li>
        <li className="puceLink"><Link className="Link" onClick={deconnect} to="/connexion">{location.pathname=== "/connexion" && showMenu && <img src={patte} alt='empreinte de chat' />}{token? "Déconnexion" : "Connexion"}</Link></li>
      </ul>

    </nav>
  );
};

export default Navigation;