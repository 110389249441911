import React from 'react';
import chatonSauvage from "../assets/pictures/chatonsauvage.png";
import jouetChat from "../assets/pictures/jouetchat.png"
import "../stylesMin/Page404.css"

const Page404 = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    let url = `${apiUrl}`;
    return (
        <div className='container404'>
            <p className='message1'>Oh nooon ! vous vous êtes perdu et Mysti404 vous attaque !</p>
            <div className='chatonSauvage'>            
                <img className='imgChaton' src={chatonSauvage} alt="chaton qui sort ces griffes"/>
                <div className='message404'>                
                    <p className='error'>Erreur 404</p>
                    <p className='cliquezVite'>Cliquez vite sur son jouet pour le calmer et retourner a l'accueil ! </p>
                </div>

            </div>
            <a href={url}><img className='souris' src={jouetChat} alt="chat gris super heros" /></a>
        </div>
    );
};

export default Page404;