import React, { useEffect, useState } from 'react';
import "../stylesMin/Carousel.css";

const Carousel = ({ object}) => {
  const [imgObject, setImgObjects]=useState([]);

  useEffect(() => {
    if (object.pictures.length === 0) {
      setImgObjects([object.mainPicture]);
    } else if (object.pictures.length >= 0) {
      setImgObjects([object.mainPicture, ...object.pictures]);
    }
  }, [object.pictures, object.mainPicture]);  

  const [pictureCount, setPictureCount] = useState(0);

  const imageSuivante = () => {
    const suivant = (pictureCount + 1) % imgObject.length;
    setPictureCount(suivant);
  }

  const imagePrecedente = () => {
    const precedent = (pictureCount - 1 + imgObject.length) % imgObject.length;
    setPictureCount(precedent);
  }
  const afficherNavigation = imgObject.length > 1;
  
  return (
    <div className='carousel'>
      {afficherNavigation ? (
        <div className='carousel-controls'>
          <i className="fa-solid fa-angle-left" onClick={imagePrecedente}></i>
          <img src={imgObject[pictureCount]} className='carousel-image' alt={object.title} />
          <i className="fa-solid fa-angle-right" onClick={imageSuivante}></i>
        </div>
      ) : <img src={imgObject[0]} className='carousel-image' alt={object.title} />}
      <p className='count'>{pictureCount + 1}/{imgObject.length}</p>
    </div>
  );
};

export default Carousel;