import React, {useState,useEffect}from 'react';
import corbeille from "../assets/icones/corbeille.png";
import "../stylesMin/modeEdition.css";
import crayon from "../assets/icones/crayon.png";
import ModaleData from './ModaleData';
import { useLocation } from 'react-router-dom';
import DeleteModal from './DeleteModal';

const BtnEdition = ({formOf, data, dataActivity, animal, style={}, style2={} }) => {
  const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteOpen, setIsDeleteOpen] = useState(false);

    const handleModaleClick = () => {
      setIsModalOpen(true);
      setIsDeleteOpen(false);
    };

  const handleModaleClick2 = () => {
    setIsDeleteOpen(true);
  }
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setIsDeleteOpen(false);
    };
  
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modale-container')) {
          setIsModalOpen(false);
        }};
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    return (
        <div className='btnEdition' style={location.pathname === "/activitiesEdit" ? style2: style}  >
            <button> <img className='iconeEdition' src={corbeille} alt="icone corbeille" onClick={handleModaleClick2} /></button>
            <button> <img className='iconeEdition' src={crayon} alt="icone crayon" onClick={handleModaleClick} /></button>
            {isModalOpen && (<ModaleData data={data} dataActivity={dataActivity} formOf={formOf} typeData="pageActu" onClose={handleCloseModal} />)}
            {deleteOpen && <DeleteModal animal={animal} data={data} dataActivity={dataActivity} onClose={handleCloseModal}/>}
        </div>
    );
  };

export default BtnEdition;