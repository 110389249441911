
export const getAdminToken = (fetchToken) => ({
    type: "getAdminToken",
    payload: {
        adminToken: fetchToken
    }
});
 export const getUserToken = (fetchToken) => ({
    type: "getUserToken",
    payload: {
        userToken: fetchToken
    }
 });

 export const getRole = (userRole) => ({
    type: "getRole",
    payload: {
        role: userRole
    }
 });

 export const getUsername = (userPseudo) => ({
    type: "getUsername",
    payload: {
        username: userPseudo
    }
 });

 export const clearDatas = () => ({
    type: "clearDatas"
});