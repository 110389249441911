import React, { useState, useEffect } from 'react';
import "../stylesMin/Adoption.css"
import femelle from '../assets/icones/femelle.png';
import male from '../assets/icones/male.png';
import inconnu from '../assets/icones/inconnu.png';
import Modale from './Modale';
import BtnEdition from "../components/BtnEdition.jsx";
import Sterile from "../assets/icones/sterile.png";
import Primo from "../assets/icones/vaccin.png";
import Vaccin from "../assets/icones/vaccinrappel.png";
import Identifier from "../assets/icones/carteidentite.png";

const Cards = ({role, animal, formOf}) => {
    const [dataAnimals, setDataAnimals] = useState([]);
    const [selectedObjet, setSelectedObjet] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading]=useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const handleModaleClick = (objet) => {
        setSelectedObjet(objet);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
  
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (e.target.classList.contains('modale-container')) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        let fetchData = () => {
            fetch(`${apiUrl}/api/${animal}/`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then(data => {
                    setDataAnimals(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error("Fetch error:", error);
                });
        };
        fetchData();
    }, [apiUrl,animal]);


    return (
        <div className='cards'>
            {isLoading && <span className='loading'>Chargement...</span> }
            {dataAnimals.length === 0 && <span className='noCat'>Pas de félin ici pour l'instant, revenez plus tard !</span> }
            {dataAnimals.map((objet) => {
                const adoption = new Date(objet.adoption);
                const day = adoption.getDate();
                const month = adoption.getMonth()+1;
                const year = adoption.getFullYear();
                const adoptionDate = `${day}/${month}/${year}`;
                // Logique de calcul de l'âge
                const currentDate = new Date();
                const birthDate = new Date(objet.birthDate)
                const ageInMilliseconds = currentDate - birthDate;
                // Conversion en années
                const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365));
                // Conversion en mois
                const ageInMonths = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 30.44)) + " mois";

                let genreImage;
                if (objet.gender === "F"||objet.gender === "femelle") {
                    genreImage = femelle;
                }if (objet.gender === "M" || objet.gender === "male") {
                    genreImage = male;
                }if (objet.gender === "I" || objet.gender === "inconnu") {
                    genreImage = inconnu;
                }

                return (
                    <div className='cardsFlex' key={objet._id} alt={`description de ${objet.name}`}>
                        {role==="admin" && <BtnEdition animal={animal} data={objet} formOf={formOf} style={{position:"absolute", top:"0px", right:"0px"}}/>} 
                        <img className='mainPicture' src={objet.mainPicture} alt={objet.name} onClick={() => handleModaleClick(objet)} loading='lazy' />
                        <div>
                            <span className='name'>{objet.name}</span>
                            <div className='infoKittens'>
                                <img className='genre' src={genreImage} alt="genre du chaton" />
                                <p>Age :<br /> {ageInYears < 1 ? ageInMonths : ageInYears}{animal==="cats" && <span> ans</span> }</p>
                                {objet.adoption && <p>Disponible : <br /> {adoptionDate}</p>}
                            </div>
                            <div className='situation'>
  {objet.sickness && JSON.parse(objet.sickness).length > 0 ? (
    <>      
      {JSON.parse(objet.sickness).includes("Primo-vaccin") && (<img className='situationLogo' src={Primo} alt='primo-vaccin fait' />)}
      {JSON.parse(objet.sickness).includes("Vaccin") && (<img className='situationLogo' src={Vaccin} alt='vaccin rappel fait' />)}
      {JSON.parse(objet.sickness).includes("Sterile") && (<img className='situationLogo' src={Sterile} alt='stérilisation faite' />)}
      {JSON.parse(objet.sickness).includes("Identifier") && (<img className='situationLogo' src={Identifier} alt='identification faite' />)}
    </>
  ) : (
    <p>Pas d'informations pour l'instant</p>
  )}
</div>
</div>
</div>
    );
    })}
        {isModalOpen && selectedObjet && (<Modale objet={selectedObjet} typeData="pageAdoption" onClose={handleCloseModal} />)}
        </div>
    );
};

export default Cards;

