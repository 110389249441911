import React from 'react'
import LogIn from '../components/formulaire/LogIn'
import "../stylesMin/Connexion.css"
import chaton from "../assets/pictures/2chaton.png"
import chatPapier from "../assets/pictures/chat-dechire-papier.png";

function Connexion() {

  return (
    <>
      <div className='connexion'>
      <h3>Connection</h3>   
      <div> 
      <div className='formStyle'> 
        <LogIn/>
      </div>
      <div className='pictureCat'>
        <img src={chaton} alt="deux chaton"/>
        <img src={chatPapier} alt="chat qui déchire l'écran"/>  
      </div>
      </div>
      </div>
    </>

  )
}
export default Connexion