import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getRole, getUserToken, getUsername} from "../../actions";

const LogIn = () => {
  const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const login = (e) => {
      e.preventDefault();
      const apiUrl = process.env.REACT_APP_API_URL;
    
      fetch(`${apiUrl}/api/user/login`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
        .then(response => {
          let erreur;
    
          if ((email.value && password.value) && !response.ok) {
            erreur = 'Adresse email ou mot de passe incorrect';
          }
          if ((!email.value || !password.value) && !response.ok) {
            erreur = 'Veuillez saisir tous les champs';
          }
    
          if (erreur) {
            setErrorMessage(erreur);
          } else {
            setErrorMessage('');
          }
    
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Réponse non valide');
          }
        })
        .then(data => {
          if (data && data.role==="admin") {
            navigate('/admin');
            localStorage.setItem("token", data.token);
            localStorage.setItem("role", data.role);
            localStorage.setItem("username", data.pseudo);
            let userLocalStor = localStorage.getItem("token");
            let roleLocalStor = localStorage.getItem("role");
            let usernameLocalStor = localStorage.getItem("username")
            dispatch(getUserToken(userLocalStor));
            dispatch(getRole(roleLocalStor));
            dispatch(getUsername(usernameLocalStor));
          } else if(data && data.role==="user"){
            localStorage.setItem("token", data.token);
            localStorage.setItem("role", data.role);
            localStorage.setItem("username", data.pseudo);
            let userLocalStor = localStorage.getItem("token");
            let roleLocalStor = localStorage.getItem("role");
            let usernameLocalStor = localStorage.getItem("username")
            dispatch(getUserToken(userLocalStor));
            dispatch(getRole(roleLocalStor));
            dispatch(getUsername(usernameLocalStor))
            navigate('/kittens');
          } else{
            setErrorMessage("Paire identifiant/Mot de passe incorrecte")
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    return (
        <div className='logIn'>
            <h5>Me connecter</h5>
            <form className='formLogIn' onSubmit={login}>
            <label htmlFor="email" name="email">E-mail :</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Adresse email" />
            <label htmlFor="password" name="password">Mot de passe :</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" />
            <button type="submit" className='buttonSubmit'>Se connecter</button>
          </form>
          <div id="erreur">{errorMessage}</div>
        </div>
    );
};

export default LogIn;