import React, {useState} from 'react';
import chatDrole from "../../assets/pictures/chatdrole2.png";

const FormActu = () => {
    const [titleActu, setTitleActu] = useState('');
    const [lieuActu, setLieuActu] = useState('');
    const [linkActu, setLinkActu] = useState('');
    const [descriptionActu , setDescriptionActu] = useState("");
    const [dateActu,setDateActu]=useState("");
    const [mainPictureActu,setMainPictureActu]=useState("");
    const [morePicture,setMorePicture]=useState([]);
    const [message,setMessage]=useState("")

    let userLocalStor = localStorage.getItem("token");

      const handleImageChange = (e) => {
       const selectedFiles = Array.from(e.target.files);
       const selectedImages = selectedFiles.slice(0, 5); // Limitez le nombre d'images à 5
       setMorePicture(selectedImages);
    };
    const dateUpgrade = new Date();

    const formData = new FormData();
    formData.append('title', titleActu);
    formData.append("place",lieuActu)
    formData.append('description', descriptionActu);
    if (morePicture.length > 0) {
    formData.append('pictures', morePicture[0]); // Utilisez le premier fichier
    for (let i = 1; i < morePicture.length; i++) {
    formData.append('pictures', morePicture[i]);
    }
    }      
    formData.append('mainPicture',mainPictureActu);
    formData.append("type", 'actuality');
    formData.append("link",linkActu);
    formData.append("date_begin",dateActu);
    formData.append("dateUpgrade", dateUpgrade);

    const addActu = (e) => {
      e.preventDefault();
      const apiUrl = process.env.REACT_APP_API_URL;
    
      fetch(`${apiUrl}/api/actualities`, {
        method: 'POST',
        headers: {
          'Authorization':`Bearer ${userLocalStor}`,
        },
        body: formData
      })
        .then(response => {
          let message;
    
          if ((!titleActu || !dateActu || !descriptionActu || !lieuActu|| !mainPictureActu)) {
            message = 'Veuillez saisir tous les champs avec une astérisque';
            return;
          }
          if (message) {
            setMessage(message);
          } else {
            setMessage('');
          }
    
          if (response.ok){
            message="actualité bien enregistré"
            // Réinitialisez l'état de tous les champs après une soumission réussie
            setTitleActu('');
            setLieuActu('');
            setDateActu('');
            setMainPictureActu('');
            setMorePicture([]);
            setLinkActu("");
            setDescriptionActu('');
          }
        })
        .then(response => {
          return response.json();
        })        
        .catch(error => {
          console.error('Error:', error);
        });
    };

    return (
<div className='formGenerale'>
    <h5>Ajouter une actualité</h5>
    <form className='formFlex' onSubmit={addActu} method="post" enctype="multipart/form-data">

      <label htmlFor="titleActu" name="titleActu">*Titre :</label>
      <input type="text" id="titleActu" value={titleActu} onChange={(e) => setTitleActu(e.target.value)} placeholder="Titre de l'actualité" />

      <label htmlFor="lieuActu" name="lieuActu">*Lieu :</label>
      <input type="text" id="lieuActu" value={lieuActu} onChange={(e) => setLieuActu(e.target.value)} placeholder="Lieu de l'actualité" />

      <label htmlFor="date">*Sélectionnez une date :</label>
      <input type="date" id="date1" name="date1" value={dateActu} onChange={(e) => setDateActu(e.target.value)}/>

      <label htmlFor="mainPictureActu">*Image principale :</label>
      <input type="file" name="mainPicture" id="mainPictureActu"  onChange={(e) => setMainPictureActu(e.target.files[0])} />

      <label htmlFor="PictureActu">Image en plus (max 5) :</label>
      <div className='containerPicture'>
      <input className='inputFile' type="file" name="pictures" id="PictureActu"  multiple onChange={handleImageChange} />
      </div>

      <label htmlFor="linkActu" name="linkActu">Lien événement :</label>
      <input type="text" id="linkActu" value={linkActu} onChange={(e) => setLinkActu(e.target.value)} placeholder="Lien de l'actualité" />

      <label htmlFor="descriptionActu">*Description :</label>
      <textarea id="descriptionActu" value={descriptionActu} onChange={(e) => setDescriptionActu(e.target.value)} rows="10" placeholder="Description de l'actualité"></textarea>
      <div className='btnEnvoiAdmin'>
      <img src={chatDrole} alt="chat noir et blanc" />  
      <button type="submit" className='buttonSubmit'>Envoyez</button>
      <p>{message}</p>
      </div>
    </form>
    </div>
    );
};

export default FormActu;