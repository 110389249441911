import React, { useState } from 'react';
import chatDrole from "../../assets/pictures/chatdrole2.png";

const FormActuEdit = ({ dataActivity }) => {
    let data = dataActivity;
    const dateBegin = new Date(data.date_begin);
    const dateBeginVal = dateBegin.toISOString().split('T')[0];
    const [titleActu, setTitleActu] = useState(data.title || "");
    const [lieuActu, setLieuActu] = useState(data.place || "");
    const [linkActu, setLinkActu] = useState(data.link);
    const [descriptionActu, setDescriptionActu] = useState(data.description || "");
    const [dateActu, setDateActu] = useState(dateBeginVal);
    const [mainPictureActu, setMainPictureActu] = useState(data.mainPicture || "");
    const [morePictureActu, setMorePictureActu] = useState(data.pictures || []);
    const [errorMessage, setErrorMessage] = useState("");
    const [isModified, setIsModified] = useState(false);

    let type = 'actuality';

    let userLocalStor = localStorage.getItem("token");

    const handleRemoveImage = (index) => {
        const updatedImages = morePictureActu.filter((_, i) => i !== index);
        setMorePictureActu(updatedImages);
    };
   
    const handleAddImage = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const selectedImages = selectedFiles.slice(0, 5 - morePictureActu.length); // Limitez le nombre d'images ajoutées pour ne pas dépasser 5
        const newImages = selectedImages.map((file) => URL.createObjectURL(file)); // Créez des URL d'objets pour les nouvelles images
        setMorePictureActu((prevImages) => [...prevImages, ...newImages]);
    };

    const formData = new FormData();
    formData.append('title', titleActu);
    formData.append("place", lieuActu)
    formData.append('description', descriptionActu);
    if (morePictureActu.length > 0) {
        formData.append('pictures', morePictureActu[0]); // Utilisez le premier fichier
        for (let i = 1; i < morePictureActu.length; i++) {
            formData.append('pictures', morePictureActu[i]);
        }};
    formData.append('mainPicture', mainPictureActu);
    formData.append("type", type);
    formData.append("link", linkActu);
    formData.append("date_begin", dateActu);
    formData.append("dateUpgrade", new Date().toISOString().split('T')[0]);

    const updateActu = (e) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        e.preventDefault();
        e.preventDefault();
        let id = data._id;
        formData.append('id', id); // Ajouter l'ID au formData

        fetch(`${apiUrl}/api/actualities/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${userLocalStor}`,
            },
            body: formData
        })
            .then(response => {
                let erreur;

                if ((!titleActu || !dateActu || !descriptionActu || !lieuActu || !mainPictureActu)) {
                    erreur = 'Veuillez saisir tous les champs avec une astérisque';
                    return;
                }
                if (response.ok) {
                    erreur = "Votre actualité a été modifiée avec succès.";
                    setIsModified(true);
                    setTitleActu('');
                    setLieuActu('');
                    setDateActu('');
                    setMainPictureActu('');
                    setMorePictureActu([]);
                    setLinkActu("");
                    setDescriptionActu('');
                }
                if (erreur) {
                    setErrorMessage(erreur);
                } else {
                    setErrorMessage('');
                }
            })
            .then(response => {
                return response.json();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
    <div>
        {isModified ? <p className='modalMsg'>{errorMessage}</p> : <div className='formGenerale'>
    <h5>Modifier une actualité</h5>
    <form className='formFlex' onSubmit={updateActu} method="put" enctype="multipart/form-data">

        <label htmlFor="titleActu" name="titleActu">*Titre :</label>
        <input type="text" id="titleActu" value={titleActu} onChange={(e) => setTitleActu(e.target.value)} placeholder="Titre de l'actualité" />

        <label htmlFor="lieuActu" name="lieuActu">*Lieu :</label>
        <input type="text" id="lieuActu" value={lieuActu} onChange={(e) => setLieuActu(e.target.value)} placeholder="Lieu de l'actualité" />

        <label htmlFor="date">*Sélectionnez une date :</label>
        <input type="date" id="date1" name="date1" value={dateActu} onChange={(e) => setDateActu(e.target.value)} />

        <label htmlFor="mainPictureActu">*Image principale :</label>
            {(mainPictureActu) && <img src={mainPictureActu} alt="Aperçu de l'actualité" style={{ width: "40%" }} />}
        <input type="file" name="mainPictureActu" id="mainPictureActu" onChange={(e) => setMainPictureActu(e.target.files[0])} />

        <label htmlFor="PictureActu">Image en plus (5 max) :</label>
        <div className='containerPicture'>
            {morePictureActu.map((image, index) => (
                <div key={index} className="image-container">
                    {image && <img src={image} alt={`${index}`} className="preview-image" style={{ width: "100%" }}/>}
                <button type="button" onClick={() => handleRemoveImage(index)}>Supprimer</button>
                </div>
                    ))}
            {morePictureActu.length < 5 && (
                <input className='inputFile' type="file" name="pictures" id="PictureKittens" multiple onChange={handleAddImage} />
                     )}
        </div>

        <label htmlFor="linkActu" name="linkActu">Lien événement :</label>
        <input type="text" id="linkActu" value={linkActu} onChange={(e) => setLinkActu(e.target.value)} placeholder="Lien de l'actualité" />

        <label htmlFor="descriptionActu">*Description :</label>
        <textarea id="descriptionActu" value={descriptionActu} onChange={(e) => setDescriptionActu(e.target.value)} rows="10" placeholder="Description de l'actualité"></textarea>
        <div className='btnEnvoiAdmin'>
        <img src={chatDrole} alt="chat noir et blanc" />
        <button type="submit" className='buttonSubmit'>Envoyez</button>
        <p>{errorMessage}</p>
        </div>
        </form>
        </div>}
        </div>
    );
};

export default FormActuEdit;