import React from 'react';
// import "../stylesMin/Header.css";
import "../styles/Header.css";
import elipse1 from "../assets/icones/Ellipse1.png";
import logo from "../assets/pictures/newLogo3.PNG";
import photo_presidente from "../assets/pictures/photo.png";
import Navigation from '../components/Navigation';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import patte from "../assets/icones/patte4.png";
import BannerEdtion from "../components/BannerEdition.jsx"
import { getRole, getUserToken, getUsername } from '../actions';
import { useSelector } from 'react-redux';

function Header() {
  const [showMenu, setShowMenu] = useState(window.innerWidth < 850);
  const [menuOpen, setMenuOpen] = useState(false);
  let adminToken = localStorage.getItem("role");
  const dispatch = useDispatch();




  useEffect(() => {
    let tokenLocalStor = localStorage.getItem("token");
    let roleLocalStor = localStorage.getItem("role");
    let usernameLocalStor = localStorage.getItem("username");

    if (tokenLocalStor) {
      dispatch(getUserToken(tokenLocalStor));
      dispatch(getRole(roleLocalStor));
      dispatch(getUsername(usernameLocalStor));
    }
  })

  const role = useSelector((state) => state.role);
  console.log(role);
  const pseudo = useSelector((state) => state.username);
  console.log(pseudo);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // Mettre à jour showMenu en fonction de la largeur de l'écran lorsqu'il change
    const handleResize = () => {
      setShowMenu(window.innerWidth < 850);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header" >
      {(adminToken === "admin") && <BannerEdtion />
        // :
        //   <div className='contactSite'>
        //     <div className='phoneLogo'><i className="fa-solid fa-phone-volume"></i><p> 06.81.96.05.98</p></div>
        //     <div className='emailLogo'><i className="fa-regular fa-envelope"></i><p>E-mail: mf.rabin@sfr.fr</p></div>
        //   </div>
      }

      <div className='text'>
        <p className='textHeader'>Association Casperetlulu</p>
        {!showMenu && <Navigation />}
        {showMenu &&
          (
            <div onClick={toggleMenu} className='divMenu'>
              <img className='patteMenu' src={patte} alt="menu déroulable" />
              <button className="burger-icon"><i className="fa-solid fa-bars" ></i></button>
              {menuOpen && <Navigation />}
            </div>
          )}

      </div>
      <div className='rectangle1'>
        <div className='rectanle2'>
        </div>
      </div>
      <div className='elipsesContainer'>
        <div className='elipses elipse1' alt='cercle à fond bleu foncé' />
        <div className='elipses photo_presidente'>
          <img className='logo' src={logo} alt="logo de l'association contenant la silhouette bleue de deux chats dans une maison." />
        </div>
      </div>
    </header>
  )
}

export default Header
