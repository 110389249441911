import React, { useState } from 'react';
import chatEtonner from "../../assets/pictures/chatetonner.png";

function FormEventEdit({ dataActivity }) {
    let data = dataActivity;

    const dateBeginVal = data.date_begin ? new Date(data.date_begin).toISOString().split('T')[0] : "";
    const dateEndVal = data.date_end ? new Date(data.date_end).toISOString().split('T')[0] : "";

    const [titleEvent, setTitleEvent] = useState(data.title || "");
    const [lieuEvent, setLieuEvent] = useState(data.place || "");
    const [linkEvent, setLinkEvent] = useState(data.link);
    const [descriptionEvent, setDescriptionEvent] = useState(data.description || "");
    const [dateBeginEvent, setDateBeginEvent] = useState(dateBeginVal);
    const [dateEndEvent, setDateEndEvent] = useState(dateEndVal);

    const [mainPictureEvent, setMainPictureEvent] = useState(data.mainPicture || "");
    const [morePictureEvent, setMorePictureEvent] = useState(data.pictures || []);
    const [errorMessage, setErrorMessage] = useState("");
    const [isModified, setIsModified] = useState(false)

    let userLocalStor = localStorage.getItem("token");

    const handleRemoveImage = (index) => {
        const updatedImages = morePictureEvent.filter((_, i) => i !== index);
        setMorePictureEvent(updatedImages);
    };
   
    const handleAddImage = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const selectedImages = selectedFiles.slice(0, 5 - morePictureEvent.length); // Limitez le nombre d'images ajoutées pour ne pas dépasser 5
        const newImages = selectedImages.map((file) => URL.createObjectURL(file)); // Créez des URL d'objets pour les nouvelles images
    
        setMorePictureEvent((prevImages) => [...prevImages, ...newImages]);
    };

    const formData = new FormData();
    formData.append('title', titleEvent);
    formData.append("place", lieuEvent)
    formData.append('description', descriptionEvent);
    if (morePictureEvent.length > 0) {
        formData.append('pictures', morePictureEvent[0]);
        for (let i = 1; i < morePictureEvent.length; i++) {
            formData.append('pictures', morePictureEvent[i]);
        }
    }
    formData.append('mainPicture', mainPictureEvent);
    formData.append("type",'event');
    formData.append("link", linkEvent);
    formData.append("date_begin", dateBeginEvent);
    formData.append("date_end", dateEndEvent);
    formData.append("dateUpgrade", new Date().toISOString().split('T')[0]);

    const updateEvent = (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        let id = data._id;
        formData.append('id', id); // Ajouter l'ID au formData

        fetch(`${apiUrl}/api/events/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${userLocalStor}`,
            },
            body: formData
        })
            .then(response => {
                let erreur;

                if ((!titleEvent || !dateBeginEvent || !dateEndEvent || !descriptionEvent || !lieuEvent || !mainPictureEvent)) {
                    erreur = 'Veuillez saisir tous les champs avec une astérisque';
                    return;
                }
                if (response.ok) {
                    erreur = "Votre évènement a été modifié avec succès.";
                    setIsModified(true);
                    setTitleEvent('');
                    setLieuEvent('');
                    setDateBeginEvent('');
                    setDateEndEvent('');
                    setMainPictureEvent('');
                    setMorePictureEvent([]);
                    setDescriptionEvent('');
                }
                if (erreur) {
                    setErrorMessage(erreur);
                } else {
                    setErrorMessage('');
                }
            })
            .then(response => {
                return response.json();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <div>
        {isModified ? <p className='modalMsg'>{errorMessage}</p> :<div className='formGenerale'>
            <h5>Modifier un événement</h5>
            <form className='formFlex' onSubmit={updateEvent} method="put" encType="multipart/form-data">

                <label htmlFor="titleEvent" >*Titre :</label>
                <input type="text" name="titleEvent" id="titleEvent" value={titleEvent} onChange={(e) => setTitleEvent(e.target.value)}  />

                <label htmlFor="lieuEvent" >*Lieu :</label>
                <input type="text" name="lieuEvent" id="lieuEvent" value={lieuEvent} onChange={(e) => setLieuEvent(e.target.value)}/>

                <label htmlFor="date">*Sélectionnez une date :</label>
                <div className='dateContainer'>
                    <div><span>Du :</span> <input type="date" id="date1" value={dateBeginEvent} name="date_begin" onChange={(e) => setDateBeginEvent(e.target.value)} /></div>
                    <div><span> au : </span><input type="date" id="date2" value={dateEndEvent} name="date_end" onChange={(e) => setDateEndEvent(e.target.value)} /></div>
                </div>

                <label htmlFor="mainPictureEvent">*Image principale :</label>
                {mainPictureEvent  && <img src={mainPictureEvent} alt="Aperçu de l'evenement" style={{ width: "40%" }} />}
                <input type="file" name="mainPicture" id="mainPictureEvent" onChange={(e) => {setMainPictureEvent(e.target.files[0])}} />

                <label htmlFor="PictureActu">Image en plus :</label>
                        <div className='containerPicture'>
    {morePictureEvent.map((image, index) => (
        <div key={index} className="image-container">
            {image && <img src={image} alt={`${index}`} className="preview-image" style={{ width: "100%" }}/>}
            <button type="button" onClick={() => handleRemoveImage(index)}>Supprimer</button>
        </div>
    ))}
    {morePictureEvent.length < 5 && (
        <input className='inputFile' type="file" name="pictures" id="PictureKittens" multiple onChange={handleAddImage} />
    )}
</div>

    <label htmlFor="linkEvent" >Lien événement :</label>
    <input type="text" name="link" id="linkEvent" value={linkEvent} onChange={(e) => setLinkEvent(e.target.value)} placeholder="Son nom" />

    <label htmlFor="descriptionEvent">*Description :</label>
    <textarea name='description' id="descriptionEvent" value={descriptionEvent} onChange={(e) => setDescriptionEvent(e.target.value)} rows="10" placeholder="Description de l'événement"></textarea>
                <div className='btnEnvoiAdmin'>
                    <img src={chatEtonner} alt="chat noir et blanc" />
                    <button type="submit" className='buttonSubmit'>Envoyez</button>
                    <p>{errorMessage}</p>
                </div>
            </form>
        </div>}
        </div>
    );
}

export default FormEventEdit