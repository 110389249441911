import React from 'react'
import DocButtonsHome from '../components/DocButtonsHome';
import "../stylesMin/Infos.css";
import GreyCat from "../assets/pictures/greyCat.png";
import BorderElement from '../components/BorderElement';

function Infos() {
const tab = [
  {
    number: 1,
    name: "Status",
    link:require("../assets/documents/Statuts_Casperetlulu[8892].pdf")
  },
  {
    number: 2,
    name: "Attestation de l'association",
    link: require("../assets/documents/attestation_asso.jpg")
  },
  {
    number: 3,
    name: "Publication au Journal Officiel",
    link: require("../assets/documents/JO_Inscription.pdf")
  }
];

  return (
    <div className='infosContent'>
<div className='topContain'>
<BorderElement style={{marginBottom: "9px"}} />
<BorderElement style={{marginBottom: "6px"}}/>
<BorderElement style={{marginBottom: "4px"}}/>

</div>

        <div className='infoText'>
            <h2 className='infoTitle'>Les informations sur l’association</h2>
            <p className='paragraph infoParagraph1'>L'association possède le certificat du "Journal Officiel de la République Française" pour les associations et les fondations d'entreprise (document n°1). Ce certificat autorise la création de l'association Casperetlulu, avec pour mission le sauvetage et la stérilisation des chats errants, le nourrissage, la socialisation et l'adoption des chatons, qu'ils soient avec ou sans leur maman chat. Il comprend également la stérilisation et l'essai de socialisation de la maman chat, ainsi que la garde de chats domestiques.</p>
            </div>
            <div className='infosRight'>
            <div className='infosRight2'>
           <p className='paragraph infoParagraph2'>Le statut est à jour et justifié par le document “Association déclarée sous le régime de la loi du 1er juillet 1901” (document n°2).<br/><br/> L’attestation individuelle de fin de formation est aussi disponible (document n°3), il justifie des compétences sur la connaissances des animaux de compagnie d’espèces domestiques (ACADED) catégorie “chat”.</p>
            <div className='buttonsComponents'>
                {
                    tab.map((doc) => {
                        return  <DocButtonsHome key={doc.number} name={doc.name} link={doc.link} />
                    })
                }
<div className='greyCatDiv'><img className='greyCatImg' src={GreyCat} alt="chat gris foncé aux petites oreilles" /></div>
        </div>
        </div>
            </div>
    </div>
  )
}

export default Infos
