import React, {useState} from 'react';
import chatEtonner from "../../assets/pictures/chatetonner.png";

const FormEvent = () => {
    const [titleEvent, setTitleEvent] = useState('');
    const [lieuEvent,setLieuEvent] = useState('');
    const [linkEvent, setLinkEvent] = useState('');
    const [descriptionEvent , setDescriptionEvent] = useState("");
    const [dateBeginEvent,setDateBeginEvent] = useState('');
    const [dateEndEvent,setDateEndEvent] = useState('');
    const [mainPictureEvent,setMainPictureEvent] = useState('');
    const [morePictureEvent,setMorePictureEvent] = useState('');
    const [errorMessage,setErrorMessage]=useState("");
    const dateUpgrade = new Date();

    let userLocalStor = localStorage.getItem("token");

    const handleImageChange = (e) => {
      const selectedFiles = Array.from(e.target.files);
      const selectedImages = selectedFiles.slice(0, 5);
      setMorePictureEvent(selectedImages);
    };

    const formData = new FormData();
    formData.append('title', titleEvent);
    formData.append("place",lieuEvent)
    formData.append('description', descriptionEvent);
    if (morePictureEvent.length > 0) {
      formData.append('pictures', morePictureEvent[0]); // Utilisez le premier fichier
      for (let i = 1; i < morePictureEvent.length; i++) {
        formData.append('pictures', morePictureEvent[i]);
      }
    }      
    formData.append('mainPicture',mainPictureEvent);
    formData.append("type", 'event');
    formData.append("link",linkEvent);
    formData.append("date_begin",dateBeginEvent);
    formData.append("date-end",dateEndEvent);
    formData.append("dateUpgrade", dateUpgrade);

    const addEvent = (e) => {
      e.preventDefault();
      const apiUrl = process.env.REACT_APP_API_URL;
    
      fetch(`${apiUrl}/api/actualities`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization':`Bearer ${userLocalStor}`,
        },
        body: formData
      })
        .then(response => {
          let erreur;
    
          if ((!titleEvent || !dateBeginEvent|| !dateEndEvent  || !descriptionEvent || !lieuEvent || !mainPictureEvent )) {
            erreur = 'Veuillez saisir tous les champs avec une astérisque';
            return;
          }
          if (erreur) {
            setErrorMessage(erreur);
          } else {
            setErrorMessage('');
          }
          if (response.ok){
            erreur="Evenement bien enregistré"
            setTitleEvent('');
            setLieuEvent('');
            setDateBeginEvent('');
            setDateEndEvent('');
            setMainPictureEvent('');
            setMorePictureEvent([]);
            setDescriptionEvent('');
          }
        })
        .then(response => {
          return response.json();
        })        
        .catch(error => {
          console.error('Error:', error);
        });
    };

    return (
        <div className='formGenerale'>
        <h5>Ajouter un événement</h5>
          <form className='formFlex' onSubmit={addEvent} method="post" enctype="multipart/form-data">

            <label htmlFor="titleEvent" name="titleEvent">*Titre :</label>
              <input type="text" id="titleEvent" value={titleEvent} onChange={(e) => setTitleEvent(e.target.value)} placeholder="Son nom" />
             
              <label htmlFor="lieuEvent" name="lieuEvent">*Lieu :</label>
              <input type="text" id="lieuEvent" value={lieuEvent} onChange={(e) => setLieuEvent(e.target.value)} placeholder="Lieu de l'actualité" />

              <label htmlFor="date">*Sélectionnez une date :</label>
              <div className='dateContainer'> 
                <div><span>Du :</span> <input type="date" id="date1" name="date1" onChange ={(e)=> setDateBeginEvent(e.target.value)}/></div>
                <div><span> au : </span><input type="date" id="date2" name="date2"onChange ={(e)=> setDateEndEvent(e.target.value)}/></div>
              </div>
              
              <label htmlFor="mainPictureEvent">*Image principale :</label>
              <input type="file" name="mainPictureEvent" id="mainPictureEvent" onChange={(e) => setMainPictureEvent(e.target.files[0])}/>

              <label htmlFor="PictureEvent">Image en plus :</label>
              <div className='containerPicture'>
              <input className='inputFile' type="file" name="pictures" id="PictureActu"  multiple onChange={handleImageChange} />
              </div>

              <label htmlFor="linkEvent" name="linkEvent">Lien événement :</label>
              <input type="text" id="linkEvent" value={linkEvent} onChange={(e) => setLinkEvent(e.target.value)} placeholder="Son nom" />

              <label htmlFor="descriptionEvent">*Description :</label>
              <textarea id="descriptionEvent" value={descriptionEvent} onChange={(e) => setDescriptionEvent(e.target.value)} rows="10" placeholder="Description de l'événement"></textarea>
              <div className='btnEnvoiAdmin'>
                <img src={chatEtonner} alt="chat noir et blanc" />  
                <button type="submit" className='buttonSubmit'>Envoyez</button>
                <p>{errorMessage}</p>
              </div>
          </form>
        </div>
    );
};

export default FormEvent;