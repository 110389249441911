import React , { useState, useEffect }from 'react';
import "../stylesMin/Modale.css"
import Carousel from './Carousel';
import femelle from '../assets/icones/femelle.png';
import male from '../assets/icones/male.png';
import inconnu from '../assets/icones/inconnu.png';
import chatSilhouette from "../assets/icones/chat-Silhouette-Gris.png"
import Sterile from "../assets/icones/sterile.png";
import Primo from "../assets/icones/vaccin.png";
import Vaccin from "../assets/icones/vaccinrappel.png";
import Identifier from "../assets/icones/carteidentite.png";


const Modale = ({ objet, onClose , typeData }) => {
    const [typeModale,setTypeModale]=useState(false);
    // Modification du format de la date d'adoption pour affichage
    const adoption = new Date(objet.adoption);
    const day = adoption.getDate();
    const month = adoption.getMonth()+1;
    const year = adoption.getFullYear();
    const adoptionDate = `${day}/${month}/${year}`;

     // Logique de calcul de l'âge
    const currentDate = new Date();
    const birthDate = new Date(objet.birthDate)
    const ageInMilliseconds = currentDate - birthDate;

    // Conversion en années
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365));
    // Conversion en mois
    const ageInMonths = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 30.44)) + " mois";

    let genreImage;

    if (objet.gender === "F") {
        genreImage = femelle;
    } else if (objet.gender === "M") {
        genreImage = male;
    } else if (objet.gender === "I") {
        genreImage = inconnu;
    }

    useEffect(() => {
        if (typeData === "pageActu") {
          setTypeModale(false);
        } else if (typeData === "pageAdoption") {
        setTypeModale(true);
        }
      }, [typeData]);

  const handleModalClose = () => {
    onClose();
  };

  return (
    <div className="modale-container">
    <img className='chatSilhouetteGris' src={chatSilhouette} alt="silhouette de chat allongé" />
      <div className="modale">
        <div className='en-teteModale'>
          <span></span>
          {typeModale &&  <h4>{objet.name}</h4>}
          {!typeModale &&  <h4>{objet.title}</h4>}
          <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
        </div>
        <div className='image'>
        <Carousel object={objet} typeData="pageActu"/>
        </div>  
        {!typeModale && 
        <div className='textDescriptionModale'>
        <p>{objet.description}</p>
        </div> }
        {typeModale && 
        <div className='textDescriptionModaleAdoption'>
            <div className='modaleInfoKittens'>
                <img className='genreModale' src={genreImage} alt="genre du chaton"/>
                <p>Age :<br /> {ageInYears < 1 ? ageInMonths : ageInYears}</p>
                {objet.adoption && <p>Disponible le : <br /> {adoptionDate}</p>}
            </div>
            <div className='infoEnPlus'>
            <div className='situation'>
              {objet.sickness && JSON.parse(objet.sickness).length > 0 ? (
                 <>
                   {JSON.parse(objet.sickness).includes("Primo-vaccin") && (<div> <p>Primo-vaccin</p> <img className='situationLogo' src={Primo} alt='primo-vaccin fait' /></div> )}
                   {JSON.parse(objet.sickness).includes("Vaccin") && (<div> <p>Rappel de vaccin </p> <img className='situationLogo' src={Vaccin} alt='vaccin rappel fait' /></div> )}
                   {JSON.parse(objet.sickness).includes("Sterile") && (<div> <p>Stérilisé</p> <img className='situationLogo' src={Sterile} alt='stérilisation faite' /></div> )}
                   {JSON.parse(objet.sickness).includes("Identifier") && (<div> <p>Identifié</p> <img className='situationLogo' src={Identifier} alt='identification faite' /></div> )}
                 </>
              ) : (
                 <p>Pas d'informations pour l'instant</p>
                 )}
            </div>
               {objet.description && <p className='descriptionModale'>{objet.description}</p>}
            {!objet.description && <p className='descriptionModale'>Pas de description pour l'instant</p>}
            </div>
        </div> }
      </div>
    </div>
  );
};

export default Modale;