import React, { useState } from 'react';
import chat from "../../assets/pictures/chat.png";

const FormCat = () => {
  const [nomCats, setNomCats] = useState('');
  const [ageCats, setAgeCats] = useState('');
  const [genderCats, setGenderCats] = useState('');
  const [dateAdoptionCats, setDateAdoptionCats] = useState("");
  const [descriptionCats, setDescriptionCats] = useState('');
  const [isCheckedSterileCats, setIsCheckedSterileCats] = useState(false);
  const [isCheckedIdentifierCats, setIsCheckedIdentifierCats] = useState(false);
  const [isCheckedVacCats,setIsCheckedVacCats] = useState(false);
  const [isCheckedPrimoVacCats,setIsCheckedPrimoVacCats] = useState(false);
  const [sicknessCat, setSicknessCat] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [mainPicture,setMainPicture]=useState('');
  const [morePictureCats,setMorePictureCats]=useState([]);
  let userLocalStor = localStorage.getItem("token");

  const handleVacCats = (e) => {
    setIsCheckedVacCats(e.target.checked);
    updateSicknessArray("Vaccin", e.target.checked);
  };

  const handlePrimoVacCats = (e) => {
    setIsCheckedPrimoVacCats(e.target.checked);
    updateSicknessArray("Primo-vaccin", e.target.checked);
  };

  const handleSterileCats = (e) => {
    setIsCheckedSterileCats(e.target.checked);
    updateSicknessArray("Sterile", e.target.checked);
  };

  const handleIdentifierCats = (e) => {
    setIsCheckedIdentifierCats(e.target.checked);
    updateSicknessArray("Identifier", e.target.checked);
  };

  const updateSicknessArray = (value, checked) => {
    if (checked) {
      setSicknessCat((prevSickness) => [...prevSickness, value]);
    } else {
      setSicknessCat((prevSickness) => prevSickness.filter((item) => item !== value));
    }
  };

  const handleGenreChange = (e) => {
        setGenderCats(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const selectedImages = selectedFiles.slice(0, 5); // Limitez le nombre d'images à 5
    setMorePictureCats(selectedImages);
  };

  const formData = new FormData();
  formData.append('name', nomCats); //required:true
  formData.append("birthDate",ageCats);//required:true
  formData.append('gender', genderCats);//required:true
  if (morePictureCats.length > 0) {
    formData.append('pictures', morePictureCats[0]);
    for (let i = 1; i < morePictureCats.length; i++) {
      formData.append('pictures', morePictureCats[i]);
    }
  }   
  formData.append('mainPicture',mainPicture);//required:true
  formData.append("adoption", dateAdoptionCats);//required:false
  formData.append("description",descriptionCats);//required:false
  formData.append("sickness", JSON.stringify(sicknessCat));//required:false

  

  const addCat = (e) => {
    e.preventDefault();
  const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/api/Cats/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userLocalStor}`
      },
      body: formData
    })
    .then(response => {
      let erreur;
      if ((!nomCats || !ageCats || !genderCats|| !mainPicture|| !descriptionCats)) {
        erreur = 'Veuillez saisir tous les champs avec une astérisque';
        setErrorMessage(erreur);
        return;
      }
      if (response.ok){
        erreur="chat bien enregistré"
      setNomCats('');
      setAgeCats('');
      setGenderCats('');
      setDateAdoptionCats('');
      setDescriptionCats('');
      setIsCheckedSterileCats(false);
      setIsCheckedIdentifierCats(false);
      setIsCheckedVacCats(false);
      setSicknessCat([]);
      setMainPicture('');
      setMorePictureCats([]);
      }
      if (erreur) {
        setErrorMessage(erreur);
      } else {
        setErrorMessage('');
      }
    })
    .then(data => {
      if(!data)
      {
        console.log("pas de données pour le chatons")
      }
      if(data){
              console.log("Chat ajouté avec succès (front)")
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  
  return (
    <div className='formGenerale'>
      <h5>Ajouter un chat</h5>
      <form className='formFlex' id='CatsForm' method="POST" onSubmit={addCat} encType="multipart/form-data">
        <label htmlFor="nomCats" name="nomCats">*Nom :</label>
        <input type="text" id="nomCats" name="name" value={nomCats} onChange={(e) => setNomCats(e.target.value)} placeholder="Son nom" />
        <label htmlFor="ageCats">*Sélectionnez son age :</label>
        <input type="date" id="ageCats" name="birthDate" value={ageCats} onChange={(e) => setAgeCats(e.target.value)} />

        <label>*Genre :</label>
        <div className='containerGenre'>
          <div className='inputFemelle'>
            <input type="radio" id="femelleCats" name="genderCats" value="F" checked={genderCats === 'femelle'} onChange={handleGenreChange}/>
            <label htmlFor="femelleCats">Femelle</label>
          </div>
          <div className='inputMale'>
            <input type="radio"id="maleCats" name="genderCats" value="M" checked={genderCats === 'male'} onChange={handleGenreChange}/>
            <label htmlFor="maleCats">Mâle</label>
          </div>
        </div>

        <label>Situation :</label>
<div className="containerSituation">
  <div>
    <input type="checkbox" id="situation1Cats" name="situationCats" value="Primo-vaccin" checked={isCheckedPrimoVacCats} onChange={handlePrimoVacCats}/>
    <label htmlFor="situation1Cats">Primo-vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation2Cats" name="situationCats" value="Vaccin" checked={isCheckedVacCats} onChange={handleVacCats}/>
    <label htmlFor="situation2Cats">Vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation3Cats" name="situationCats" value="Stérile" checked={isCheckedSterileCats} onChange={handleSterileCats}/>
    <label htmlFor="situation3Cats">Stérile</label>
  </div>
  <div>
    <input type="checkbox" id="situation4Cats" name="situationCats" value="Identifier" checked={isCheckedIdentifierCats} onChange={handleIdentifierCats}/>
    <label htmlFor="situation4Cats">Identifier</label>
  </div>
</div>
    <>
    <label htmlFor="date">Disponible le :</label>
    <input type="date" id="date" name="adoption" value={dateAdoptionCats} onChange={(e) => setDateAdoptionCats(e.target.value)} />
    </>
    <label htmlFor="mainPictureCats">*Image principale :</label>
    <input type="file" name="mainPicture" id="mainPictureCats"  onChange={(e) => setMainPicture(e.target.files[0])}/>
    <label htmlFor="PictureEvent">Image en plus :</label>
    <div className='containerPicture'>
    <input className='inputFile' type="file" name="pictures" id="PictureActu"  multiple onChange={handleImageChange} />
    </div>
        <label htmlFor="descriptionCats">Description :</label>
        <textarea id="descriptionCats" name='description'value={descriptionCats} onChange={(e) => setDescriptionCats(e.target.value)} rows="10" placeholder="Description du chaton"></textarea>
        <div className='btnEnvoiAdmin'>
        <img src={chat} alt="chat noir et blanc" />  
          <button type="submit" className='buttonSubmit'>Envoyez</button>
          <p>{errorMessage}</p>
        </div>
      </form>
    </div>
  );
};

export default FormCat;