import React, { useState } from 'react';
import chat from "../../assets/pictures/chat.png";
import Sterile from "../../assets/icones/sterile.png";
import Primo from "../../assets/icones/vaccin.png";
import Vaccin from "../../assets/icones/vaccinrappel.png";
import Identifier from "../../assets/icones/carteidentite.png";

function FormCatEdit({ dataObject}) {
    let data = dataObject;
    const birthDate = new Date(data.birthDate);
    const birthValue = birthDate.toISOString().split('T')[0]; // Prendre seulement la partie YYYY-MM-DD
    const adoption = new Date(data.adoption);
    const adoptionValue =  adoption.toISOString().split('T')[0];

    const [nomCats, setNomCats] = useState(data.name);
    const [ageCats, setAgeCats] = useState(birthValue);
    const [genderCats, setGenderCats] = useState(data.gender);
    const [dateAdoptionCats, setDateAdoptionCats] = useState(adoptionValue);
    const [descriptionCats, setDescriptionCats] = useState(data.description);
    const [isCheckedPrimoVacCats, setIsCheckedPrimoVacCats] = useState(false);
    const [isCheckedSterileCats, setIsCheckedSterileCats] = useState(false);
    const [isCheckedVacCats, setIsCheckedVacCats] = useState(false);
    const [isCheckedIdentifierCats, setIsCheckedIdentifierCats] = useState(false);
    const sicknessData = useState(data.sickness[0]);
    const [sicknessPut,setSicknessPut]=useState(false)
    const [sickness,setSickness]=useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [mainPicture, setMainPicture] = useState(data.mainPicture);
    const [morePictureCats, setMorePictureCats] = useState(data.pictures || []);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isAvailable, setIsAvailable] = useState(dataObject.adoption ? false : true );
    const [isModified, setIsModified] = useState(false)

    let userLocalStor = localStorage.getItem("token");

    const handlePrimoVacChange = (e) => {
        setSicknessPut(true)
        setIsCheckedPrimoVacCats(e.target.checked);
        updateSicknessArray("Primo-vaccin", e.target.checked);
    };
    
      const handleVacChange = (e) => {
        setSicknessPut(true)
        setIsCheckedVacCats(e.target.checked);
        updateSicknessArray("Vaccin", e.target.checked);
      };
      
      const handleSterileChange = (e) => {
        setSicknessPut(true)
        setIsCheckedSterileCats(e.target.checked);
        updateSicknessArray("Sterile", e.target.checked);
      };
      
      const handleIdentifierChange = (e) => {
        setSicknessPut(true)
        setIsCheckedIdentifierCats(e.target.checked);
        updateSicknessArray("Identifier", e.target.checked);
      };

      const updateSicknessArray = (value, checked) => {
        if (checked) {
          setSickness((prevSickness) => [...prevSickness, value]);
          return sickness
        } else {
          setSickness((prevSickness) => prevSickness.filter((item) => item !== value));
          return sickness
        }
      };

      const handleAddImage = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const selectedImages = selectedFiles.slice(0, 5 - morePictureCats.length); // Limitez le nombre d'images ajoutées pour ne pas dépasser 5
        const newImages = selectedImages.map((file) => URL.createObjectURL(file)); // Créez des URL d'objets pour les nouvelles images
    
        setMorePictureCats((prevImages) => [...prevImages, ...newImages]);
    };
    
    const handleRemoveImage = (index) => {
        const updatedImages = morePictureCats.filter((_, i) => i !== index);
        setMorePictureCats(updatedImages);
    };

    const handleGenreChange = (e) => {
        setGenderCats(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleAvailabilityChange = (e) => {
        setIsAvailable(e.target.checked);
    };

    const formData = new FormData();
    formData.append('name', nomCats); //required:true
    formData.append("birthDate", ageCats);//required:true
    formData.append('gender', genderCats);//required:true
    if (morePictureCats.length > 0) {
        formData.append('pictures', morePictureCats[0]); // Utilisez le premier fichier
        for (let i = 1; i < morePictureCats.length; i++) {
            formData.append('pictures', morePictureCats[i]);
        }};
    formData.append('mainPicture', mainPicture);//required:true
    formData.append("description", descriptionCats);//required:false
    if(isAvailable===false) {
        formData.append('adoption', dateAdoptionCats);
    }

    const updateCat = (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        let id = data._id;
        formData.append('id', id); // Ajoutez l'ID au formData
        if (sickness === sicknessData){
            formData.append("sickness", JSON.stringify(sicknessData));
        } if (sickness !== undefined && sickness!==sicknessData && sicknessPut) {
            formData.append("sickness", JSON.stringify(sickness));
        }

        fetch(`${apiUrl}/api/cats/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${userLocalStor}`
            },
            body: formData
        })
            .then(response => {
                let erreur;
                if ((!nomCats || !ageCats || !genderCats || !mainPicture || !descriptionCats)) {
                    erreur = 'Veuillez saisir tous les champs avec une astérisque';
                    setErrorMessage(erreur);
                    return;
                }
                if (response.ok) {
                    erreur = "chat bien enregistré";
                    setIsModified(true);
                }
                if (erreur) {
                    setErrorMessage(erreur);
                } else {
                    setErrorMessage('');
                }
                return response.json();
            })
            .then(data => {
                if (data === undefined) {
                    console.log("pas de données pour le chatons T.T")
                }
                if (data !== undefined) {
                    console.log("Chat ajouté avec succès (front)")
                }
                // Rediriger l'utilisateur ou effectuer d'autres actions après la réussite
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <div >
            {isModified ? <p>{errorMessage}</p> :  <div className='formGenerale'>
            <h5>Modifier un chat</h5>
            <form className='formFlex' id='CatsForm' method="PUT" onSubmit={updateCat} encType="multipart/form-data">
                <label htmlFor="nomCats" name="nomCats">*Nom :</label>
                <input type="text" id="nomCats" name="name" value={nomCats} onChange={(e) => setNomCats(e.target.value)} placeholder="Son nom" />
                <label htmlFor="ageCats">*Sélectionnez son age :</label>
                <input type="date" id="ageCats" name="birthDate" value={ageCats} onChange={(e) => setAgeCats(e.target.value)} />

                <label>*Genre :</label>
                <div className='containerGenre'>
                    <div className='inputFemelle'>
                        <input type="radio" id="femelleCats" name="genderCats" value="F" checked={genderCats === 'F'} onChange={handleGenreChange}/>
                        <label htmlFor="femelleCats">Femelle</label>
                    </div>
                    <div className='inputMale'>
                        <input type="radio" id="maleCats" name="genderCats" value="M" checked={genderCats === 'M'} onChange={handleGenreChange}/>
                        <label htmlFor="maleCats">Mâle</label>
                    </div>
                </div>

                <label>Situation :</label>   
                    <p>données actuelles</p>  
                    <div className='donneesDataSickness'>   
                    {JSON.parse(data.sickness).includes("Primo-vaccin") && (<div> <p>Primo-vaccin</p> <img className='situationLogo' src={Primo} alt='primo-vaccin fait' /></div> )}
                    {JSON.parse(data.sickness).includes("Vaccin") && (<div> <p>Rappel de vaccin </p> <img className='situationLogo' src={Vaccin} alt='vaccin rappel fait' /></div> )}
                    {JSON.parse(data.sickness).includes("Sterile") && (<div> <p>Stérilisé</p> <img className='situationLogo' src={Sterile} alt='stérilisation faite' /></div> )}
                    {JSON.parse(data.sickness).includes("Identifier") && (<div> <p>Identifié</p> <img className='situationLogo' src={Identifier} alt='identification faite' /></div> )}
                    </div>  
<p>données si modification</p>
<div className="containerSituation">
  
  <div>
    <input type="checkbox" id="situation1Cats" name="situationCats" value="Primo-vaccin" checked={isCheckedPrimoVacCats} onChange={handlePrimoVacChange}/>
    <label htmlFor="situation1Cats">Primo-vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation2Cats" name="situationCats" value="Vaccin" checked={isCheckedVacCats} onChange={handleVacChange}/>
    <label htmlFor="situation2Cats">Vaccin</label>
  </div>
  <div>
    <input type="checkbox" id="situation3Cats" name="situationCats" value="Sterile" checked={isCheckedSterileCats}onChange={handleSterileChange}/>
    <label htmlFor="situation3Cats">Stérile</label>
  </div>
  <div>
    <input type="checkbox" id="situation4Cats" name="situationCats" value="Identifier" checked={isCheckedIdentifierCats} onChange={handleIdentifierChange}/>
    <label htmlFor="situation4Cats">Identifier</label>
  </div>
</div>
    <div className='avaibility' style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "10px" }}>
      <label htmlFor="availabilityCheckbox">Chat disponible tout de suite</label>
      <input type="checkbox" id="availabilityCheckbox" name="availability" checked={isAvailable} onChange={handleAvailabilityChange} style={{ display: "block", alignSelf: "end", marginBottom: "8px" }}/>
                </div>
                {isAvailable===false && <>
                    <label htmlFor="date">Disponible le :</label>
                    <input type="date" id="date" name="adoption" value={dateAdoptionCats} onChange={(e) => setDateAdoptionCats(e.target.value)} />
                </>}

                <label htmlFor="mainPictureCats">*Image principale :</label>
                {(mainPicture && !selectedImage) ? <img src={mainPicture} alt="Aperçu du chat" style={{ width: "40%" }} /> :
                    <img src={selectedImage} alt="Aperçu du chat" style={{ width: "40%" }} />}
                <input type="file" name="mainPicture" id="mainPictureCats" onChange={(e) => { setMainPicture(e.target.files[0]); handleImageChange(e) }} />

                <label htmlFor="PictureCats">Image en plus :</label>
                        <div className='containerPicture'>
    {morePictureCats.map((image, index) => (
        <div key={index} className="image-container">
            {image && <img src={image} alt={`${index}`} className="preview-image" style={{ width: "100%" }}/>}
            <button type="button" onClick={() => handleRemoveImage(index)}>Supprimer</button>
        </div>
    ))}
    {morePictureCats.length < 5 && (
        <input className='inputFile' type="file" name="pictures" id="PictureCats" multiple onChange={handleAddImage} />
    )}
</div>

    <label htmlFor="descriptionCats">Description :</label>
    <textarea id="descriptionCats" name='description' value={descriptionCats} onChange={(e) => setDescriptionCats(e.target.value)} rows="10" placeholder="Description du chaton"></textarea>

                <div className='btnEnvoiAdmin'>
                    <img src={chat} alt="chat noir et blanc" />
                    <button type="submit" className='buttonSubmit'>Envoyez</button>
                    <p>{errorMessage}</p>
                </div>
            </form>
        </div>}
</div>
    )
}

export default FormCatEdit
