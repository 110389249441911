import React from 'react';
import "../stylesMin/Infos.css";

// Les boutons pour ouvrir les documents dans la section info

function DocButtonsHome({name, link}) {
  return (
        <a className="buttonLink" href={link} download ><button className='docButtonHome'>{name}</button></a>       
  )
}

export default DocButtonsHome
